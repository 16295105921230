import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { routes } from "@/pages/routes";
import { errorSlice } from "@/store/error";
import { zodResolver } from "@hookform/resolvers/zod";
import { getAuthState, loginThunk } from "@/store/auth";
import { useAppDispatch } from "@/hooks/useAppDispatch.hook";
import { Button, FormFieldCheckbox, FormFieldInput } from "@/ui";
import { ErrorMessage, Page, StyledForm, Title } from "@/components";
import { useFormErrorFromBackend } from "@/hooks/useFormErrorFromBackend";
import {
  formSchemaLoginPage,
  FormSchemaLoginPage,
} from "@/constants/validateSchema";

import style from "./LoginPage.module.scss";

const defaultValuesForm: FormSchemaLoginPage = {
  email: "",
  password: "",
  remember_me: false,
};

export const LoginPage = () => {
  const dispatch = useAppDispatch();
  const auth = useSelector(getAuthState);
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isSubmitted, isDirty },
    setError,
  } = useForm<FormSchemaLoginPage>({
    resolver: zodResolver(formSchemaLoginPage),
    defaultValues: defaultValuesForm,
  });

  useFormErrorFromBackend<FormSchemaLoginPage>({
    setError,
    fieldNames: ["email", "password"],
  });

  const onSubmitHandler = (values: FormSchemaLoginPage) => {
    const trust_token = localStorage.getItem("trust_token");
    if (trust_token) {
      values = { ...values, trust_token };
    }
    dispatch(
      loginThunk({
        data: values,
        navigate,
      }),
    );
  };

  useEffect(() => {
    dispatch(errorSlice.actions.resetError());
  }, []);

  return (
    <Page>
      <Title title={"Вход в личный кабинет"} />
      <StyledForm handleSubmit={handleSubmit(onSubmitHandler)}>
        <FormFieldInput<FormSchemaLoginPage>
          type="input"
          name="email"
          isRequiredField
          title={"E-mail"}
          control={control}
          register={register}
          error={errors.email}
          placeholder={"Введите e-mail"}
          autoComplete={"email"}
          autoFocus
        />
        <FormFieldInput<FormSchemaLoginPage>
          type="password"
          name="password"
          control={control}
          register={register}
          error={errors.password}
          title={"Пароль"}
          placeholder={"Введите пароль"}
          isRequiredField
          autoComplete={"password"}
        />

        <div className={style.actions}>
          <FormFieldCheckbox<FormSchemaLoginPage>
            titleCheckbox={"Запомнить меня"}
            name="remember_me"
            control={control}
            error={errors.remember_me}
          />

          <Link to={routes.forgotPassword} className={style.link}>
            Забыли пароль?
          </Link>
        </div>

        <ErrorMessage />

        <Button
          className={style.submit}
          name={"Войти"}
          type={"submit"}
          disabled={
            isSubmitting ||
            (isSubmitted && !isValid && !isDirty) ||
            auth.loading
          }
          isLoading={auth.loading}
          onClick={() => {
            dispatch(errorSlice.actions.resetError());
          }}
        />
        <div className={style.other}>
          <div className={style.registrationContainer}>
            <p>У меня еще нет аккаунта.</p>
            <Link to={routes.registration} className={style.link}>
              Зарегистрироваться
            </Link>
          </div>
          <Link to={routes.otpRequest} className={style.link}>
            Войти по коду с почты
          </Link>
        </div>
      </StyledForm>
    </Page>
  );
};
