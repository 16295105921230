import style from "./EditModal.module.scss";

import { ReactNode } from "react";

import { Modal } from "@/ui";

export interface EditModalProps {
  onClose: () => void;
}

interface ContainerProps extends EditModalProps {
  title: string;
  hint?: string;
  children: ReactNode;
}

export const Container = ({
  title,
  hint,
  children,
  onClose,
}: ContainerProps) => {
  return (
    <Modal isOpen={true} onClose={onClose} title={title}>
      <div className={style.container}>
        {hint && <p className={style.hint}>{hint}</p>}

        {children}
      </div>
    </Modal>
  );
};
