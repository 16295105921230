import style from "./ProfilePage.module.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getUserState, getUserThunk, userSlice } from "@/store/user";
import { useAppDispatch } from "@/hooks/useAppDispatch.hook";
import { Button, Loader } from "@/ui";
import { ProfileField } from "@/pages/profile/components/profileField/ProfileField";
import { errorSlice } from "@/store/error";
import { routes } from "@/pages/routes";
import { getAuthState, logoutThunk } from "@/store/auth";
import { Title, Page } from "@/components";
import { ModalTypes, modalSlice } from "@/store/modal";
import { ModalLauncher } from "./containers/modal";

export const ProfilePage = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(getUserState);
  const auth = useSelector(getAuthState);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(errorSlice.actions.resetError());
    dispatch(getUserThunk());
  }, []);

  return (
    <Page>
      <Title title={"Страница пользователя"} />
      {user.loading ? (
        <Loader.Main className={style.loader} />
      ) : (
        <div className={style.profile}>
          <ProfileField title={"ФИО"} value={user.data.name} />

          <ProfileField
            title={"Пароль"}
            type={"password"}
            onEdit={() => {
              dispatch(userSlice.actions.setIsPasswordChanged(false));
              dispatch(
                modalSlice.actions.setModalType(ModalTypes.EditPassword),
              );
            }}
          />

          <ProfileField
            title={"E-mail"}
            value={user.data.email}
            confirm={{
              isConfirm: Boolean(user.data.email_verified),
              successText: "E-mail подтвержден",
              failedText: "Указанный e-mail адрес не был подтвержден.",
              handleConfirm: () => {
                dispatch(
                  modalSlice.actions.setModalType(ModalTypes.ConfirmEmail),
                );
              },
            }}
            onEdit={() => {
              dispatch(userSlice.actions.setIsEmailChanged(false));
              dispatch(modalSlice.actions.setModalType(ModalTypes.EditEmail));
            }}
          />

          <ProfileField
            title={"Мобильный телефон"}
            value={user.data.phone}
            type={"phone"}
            confirm={{
              isConfirm: Boolean(user.data.phone_verified),
              successText: "Номер подтвержден",
              failedText: "Указанный телефонный номер не был подтвержден.",
              handleConfirm: () => {
                dispatch(
                  modalSlice.actions.setModalType(ModalTypes.ConfirmPhone),
                );
              },
            }}
            onEdit={() => {
              dispatch(userSlice.actions.setIsPhoneChanged(false));
              dispatch(modalSlice.actions.setModalType(ModalTypes.EditPhone));
            }}
          />

          <div className={style.buttons}>
            <Button
              className={style.btn}
              name={"Мои приложения"}
              type={"button"}
              onClick={() => {
                navigate(routes.applications);
              }}
            />
            <Button
              className={style.btn}
              name={"Выход"}
              type={"button"}
              isLoading={auth.loading}
              onClick={() => {
                dispatch(logoutThunk(navigate));
              }}
              ghost
            />
          </div>
        </div>
      )}
      <ModalLauncher />
    </Page>
  );
};
