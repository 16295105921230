import React, { ReactPortal, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

import style from "./Modal.module.scss";
import { useOutside } from "@/hooks/useOutside.hook";
import { Icon } from "@/ui";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactElement;
  title?: string;
}

export const Modal = ({
  isOpen,
  onClose,
  children,
  title,
}: ModalProps): React.ReactElement | null => {
  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isOpen) {
        onClose();
      }
    };

    // Добавление слушателя события при монтировании компонента
    document.addEventListener("keydown", handleEscKeyPress);

    // Удаление слушателя события при размонтировании компонента
    return () => {
      document.removeEventListener("keydown", handleEscKeyPress);
    };
  }, [isOpen, onClose]);
  if (!isOpen) {
    return null;
  }
  return (
    <ModalContent onClose={onClose} isOpen={isOpen} title={title}>
      {children}
    </ModalContent>
  );
};

const ModalContent = ({
  onClose,
  children,
  title,
}: ModalProps): ReactPortal => {
  const wrapperRef = useRef(null);
  const appContainer =
    document.getElementById("app-container") ?? document.body;

  const close = (): void => {
    onClose();
  };

  useOutside({ ref: wrapperRef, outsideClick: close });

  return createPortal(
    <>
      <div className={style.modalOverlay} />
      <div
        className={style.modalWrapper}
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
      >
        <div ref={wrapperRef} className={style.modal}>
          <div className={style.modalHead}>
            {title && <h3 className={style.title}>{title}</h3>}
            <div className={style.modalClose} onClick={onClose}>
              <Icon name={"closeModal"} className={style.closeIcon} />
            </div>
          </div>

          <div className={style.modalContent}>{children}</div>
        </div>
      </div>
    </>,
    appContainer,
  );
};
