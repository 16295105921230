import React, { useState } from "react";

import style from "./Image.module.scss";
import cn from "classnames";

import { Loader } from "@/ui";

interface ImageProps {
  className?: string;
  src: string | null;
}

export const Image = ({ className, src }: ImageProps) => {
  const [imageLoading, setImageLoading] = useState(true);

  return (
    <div className={cn(style.container, className)}>
      {imageLoading && <Loader.Content className={style.loader} />}

      {src && (
        <img
          src={src}
          alt=""
          style={{ display: imageLoading ? "none" : "block" }}
          onLoad={() => {
            setImageLoading(false);
          }}
        />
      )}
    </div>
  );
};
