import style from "./Footer.module.scss";

const URL_TELEGRAM = "https://t.me/prodamus_bot";
const URL_PRODAMUS = "https://prodamus.ru/";

export const Footer = () => {
  const currentYear = new Date().getFullYear()?.toString();

  return (
    <div className={style.container}>
      <div className={style.containerButtons}>
        <span className={style.containerName}>
          {currentYear}, ООО «Продамус»
        </span>
        <a
          href={URL_PRODAMUS}
          target="_blank"
          className={style.logo}
          rel="noreferrer"
        />

        <div className={style.containerSupport}>
          <a
            href={URL_TELEGRAM}
            target="_blank"
            rel="noopener noreferrer"
            className={style.supportTelegram}
          >
            Служба поддержки
          </a>
        </div>
      </div>
    </div>
  );
};
