import { FieldError } from "react-hook-form";

import style from "./ErrorField.module.scss";

interface ErrorFieldProps {
  error?: FieldError;
}
export const ErrorField = ({ error }: ErrorFieldProps) => {
  return (
    <div className={style.container}>
      {error && <span className={style.text}>{error.message}</span>}
    </div>
  );
};
