import style from "./Toast.module.scss";

import { ToastContainer, TypeOptions } from "react-toastify";
import { Icon } from "../icon/Icon";

const customIcons: Record<TypeOptions, string> = {
  success: "okay",
  info: "warn",
  error: "warn",
  warning: "warn",
  default: "warn",
};

export const Toast = () => {
  return (
    <ToastContainer
      toastClassName={style.toast}
      closeButton={false}
      hideProgressBar={true}
      icon={({ type }) => (
        <Icon name={customIcons[type]} className={style.icon} />
      )}
    />
  );
};
