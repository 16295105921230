import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { routes } from "@/pages/routes";
import { Button, FormFieldInput } from "@/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { errorSlice, getErrorState } from "@/store/error";
import { useAppDispatch } from "@/hooks/useAppDispatch.hook";
import { getAuthState, otpConfirmThunk, twoFAThunk } from "@/store/auth";
import { useFormErrorFromBackend } from "@/hooks/useFormErrorFromBackend";
import { useResendCodeTimer } from "@/pages/twoFAPage/hooks/useResendCodeTimer";
import {
  ErrorMessage,
  GetRequestCodeAgain,
  LinkBackPage,
  Page,
  StyledForm,
  Title,
} from "@/components";
import {
  formSchemaTwoFAPage,
  FormSchemaTwoFAPage,
} from "@/constants/validateSchema";

import style from "./TwoFAPage.module.scss";

const defaultValuesForm: FormSchemaTwoFAPage = {
  code: "",
  two_fa_token: "",
};

export const TwoFAPage = () => {
  const dispatch = useAppDispatch();
  const auth = useSelector(getAuthState);
  const error = useSelector(getErrorState);
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isSubmitted, isDirty },
    setError,
  } = useForm<FormSchemaTwoFAPage>({
    resolver: zodResolver(formSchemaTwoFAPage),
    defaultValues: defaultValuesForm,
  });

  useFormErrorFromBackend<FormSchemaTwoFAPage>({
    setError,
    fieldNames: ["code"],
  });

  const onSubmitHandler = (val: FormSchemaTwoFAPage) => {
    dispatch(otpConfirmThunk({ ...val, two_fa_token: auth.data.two_fa_token }));
  };

  const getOtpCodeAgainCallback = () => {
    dispatch(errorSlice.actions.resetError());

    const two_fa_token = auth.data?.two_fa_token;
    if (!two_fa_token) {
      navigate(routes.login);
      return;
    }
    dispatch(twoFAThunk({ two_fa_token }));
  };

  const { handleClick: onGetOtpCodeAgain } = useResendCodeTimer(
    60000,
    getOtpCodeAgainCallback,
    [auth.data?.two_fa_token],
  );

  useEffect(() => {
    if (!error.hasError) {
      if (auth.isAuth) {
        if (auth.data?.redirect_to) {
          let redirectTo = auth.data.redirect_to;

          window.location.href = `${redirectTo}&returnUrl=${window.location.origin}${routes.profile}`;
        } else {
          navigate(routes.profile);
        }
      }
    }
  }, [auth.isAuth, error.hasError]);

  useEffect(() => {
    onGetOtpCodeAgain();
  }, []);

  return (
    <Page isLoading={auth.otpLoading}>
      <Title title={"Подтверждение входа"} />
      <StyledForm handleSubmit={handleSubmit(onSubmitHandler)}>
        <FormFieldInput<FormSchemaTwoFAPage>
          type="input"
          name="code"
          isRequiredField
          title={"Код подтверждения"}
          control={control}
          register={register}
          error={errors.code}
          placeholder={"Введите код"}
          autoComplete={"email"}
          autoFocus
          maxLength={6}
        />

        <GetRequestCodeAgain
          handleClickButton={onGetOtpCodeAgain}
          receiver={auth.otpEmail}
          type="email"
        />

        <ErrorMessage />

        <Button
          className={style.submit}
          name={"Подтвердить"}
          disabled={
            isSubmitting ||
            !isDirty ||
            (isSubmitted && !isValid && !isDirty) ||
            auth.loading
          }
          isLoading={auth.loading}
          type={"submit"}
          onClick={() => {
            dispatch(errorSlice.actions.resetError());
          }}
        />

        <LinkBackPage to={routes.otpRequest} />
      </StyledForm>
    </Page>
  );
};
