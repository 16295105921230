import style from "./ConfirmModal.module.scss";

import { ReactNode } from "react";

import { Modal, Loader, Icon } from "@/ui";

export interface ConfirmModalProps {
  onClose: () => void;
}

interface ContainerProps extends ConfirmModalProps {
  title: string;
  hint?: string;
  children: ReactNode;
  isLoading: boolean;
}

export const Container = ({
  title,
  hint,
  children,
  onClose,
  isLoading,
}: ContainerProps) => {
  return (
    <Modal isOpen={true} onClose={onClose} title={title}>
      <div className={style.container}>
        {isLoading ? (
          <Loader.Main className={style.containerLoader} />
        ) : (
          <>
            {hint && (
              <div className={style.hintContainer}>
                <Icon name={"warn"} className={style.exclamationPoint} />
                <p className={style.hint}>{hint}</p>
              </div>
            )}
            {children}
          </>
        )}
      </div>
    </Modal>
  );
};
