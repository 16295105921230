import { Link } from "react-router-dom";

import style from "./CheckboxTitleIsAgreeWithRules.module.scss";

const URL_PRIVACY =
  "https://service-prodamus.payform.ru/assets/pdf/privacy.pdf";

export const CheckboxTitleIsAgreeWithRules = () => {
  return (
    <div className={style.rulesTextContainer}>
      <p>Я ознакомлен с</p>
      <Link
        to={URL_PRIVACY}
        target={"_blank"}
        className={style.link}
        style={{ margin: "0px 6px 10px", fontSize: 16 }}
      >
        Политикой конфиденциальности
      </Link>
      <p>и даю свое согласие на обработку ООО "Продамус"</p>
      <Link
        to={URL_PRIVACY}
        target={"_blank"}
        className={style.link}
        style={{ margin: "0px 4px 10px", fontSize: 16 }}
      >
        моих персональных данных
      </Link>
    </div>
  );
};
