import { CSSProperties, ReactElement } from "react";

import cn from "classnames";
import { Input, InputNumber } from "antd";
import { Control, FieldError, Path, UseFormRegister } from "react-hook-form";

import { PhoneField } from "@/ui/phone-field";
import { FormSchemaCommonFields } from "@/constants/validateSchema";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import style from "./InputField.module.scss";
import {
  getInputComponentByType,
  GetInputComponentByTypeProps,
  InputAntdCommonProps,
} from "./getInputComponentByType";

type InputType = "input" | "textArea" | "password" | "phone" | "inputNumber";

export const configInputs: Record<InputType, ReactElement<unknown>> = {
  textArea: <Input.TextArea />,
  inputNumber: <InputNumber controls={false} />,
  input: <Input />,
  password: (
    <Input.Password
      iconRender={visible =>
        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
      }
    />
  ),
  phone: <PhoneField />,
};

export type InputFieldProps<T extends FormSchemaCommonFields> = {
  /** Тип поля */
  type: InputType;
  /** Текст-заполнитель */
  placeholder?: string;
  /** Уникальный ключ формы */
  name: Path<T>;
  /** Функция из react-hook-form, используемая для регистрации поля ввода в форме. */
  register: UseFormRegister<T>;
  /** Флаг, указывающий, следует ли интерпретировать значение поля как число */
  valueAsNumber?: boolean;
  /** Стили для контейнера компонента Input */
  styleInput?: CSSProperties;
  /** Флаг, true если поле обязательное */
  isRequiredField?: boolean;
  /** Обьект ошибки поля */
  error?: FieldError;
  /** Функция из react-hook-form, используемая для регистрации поля ввода в форме. */
  control: Control<T>;
} & InputAntdCommonProps;

export function InputField<T extends FormSchemaCommonFields>({
  type,
  name,
  register,
  valueAsNumber,
  ...props
}: InputFieldProps<T>) {
  const hasError = props?.error && props?.error.type;

  const stylesInput = {
    ...props?.styleInput,
    borderColor: hasError ? "#E41D36" : "#E6E6E6",
    color: hasError ? "#E41D36" : "#000",
    width: "100%",
  };

  const sharedInputComponentProps: GetInputComponentByTypeProps<T> = {
    ...props,
    type,
    name,
    register: { ...register(name, { valueAsNumber }) },
    className: cn(style.input, {
      [style.inputPhone]: type === "phone",
    }),
    style: { ...stylesInput },
    control: props.control,
  };

  return <>{getInputComponentByType(sharedInputComponentProps)}</>;
}
