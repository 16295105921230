import { CSSProperties, useRef } from "react";

import cn from "classnames";

import { useHover } from "@/hooks/useHover";

import style from "./TitleField.module.scss";
import { CustomTooltip } from "./components/CustomTooltip";

export interface TitleFieldProps {
  title?: string;
  isRequiredField?: boolean;
  tooltipTitle?: string;
  isColon?: boolean;
  styleContainer?: CSSProperties;
}

export const TitleField = ({
  title,
  isRequiredField = false,
  tooltipTitle = "",
  isColon = true,
  styleContainer,
}: TitleFieldProps) => {
  if (!title) return null;

  const hoverRef = useRef<HTMLImageElement>(null);
  const isHover = useHover<HTMLImageElement>(hoverRef);

  const titleHtml = `${title}${isColon ? ":" : ""}`;

  return (
    <div className={style.container} style={styleContainer}>
      <h3
        dangerouslySetInnerHTML={{ __html: titleHtml }}
        className={cn({ [style.text]: isRequiredField })}
      />
      <CustomTooltip
        tooltipTitle={tooltipTitle}
        hoverRef={hoverRef}
        isHover={isHover}
      />
    </div>
  );
};
