import { useEffect } from "react";

import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

import { routes } from "@/pages/routes";
import { errorSlice } from "@/store/error";
import { Button, FormFieldInput } from "@/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppDispatch } from "@/hooks/useAppDispatch.hook";
import { getAuthState, resetPasswordConfirmThunk } from "@/store/auth";
import { useFormErrorFromBackend } from "@/hooks/useFormErrorFromBackend";
import {
  ErrorMessage,
  LinkBackPage,
  Page,
  StyledForm,
  Title,
} from "@/components";
import {
  formSchemaConfirmForgotPasswordPage,
  FormSchemaConfirmForgotPasswordPage,
} from "@/constants/validateSchema";

import style from "./ConfirmForgotPasswordPage.module.scss";

const defaultValuesForm: FormSchemaConfirmForgotPasswordPage = {
  email: "",
  token: "",
  password: "",
  password_confirmation: "",
};

export const ConfirmForgotPasswordPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector(getAuthState);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isSubmitted, isDirty },
    setError,
  } = useForm<FormSchemaConfirmForgotPasswordPage>({
    resolver: zodResolver(formSchemaConfirmForgotPasswordPage),
    defaultValues: defaultValuesForm,
  });

  useFormErrorFromBackend<FormSchemaConfirmForgotPasswordPage>({
    setError,
    fieldNames: ["password", "password_confirmation"],
  });

  const onSubmitHandler = async (val: FormSchemaConfirmForgotPasswordPage) => {
    const searchParams = new URLSearchParams(location.search);
    val.token = searchParams.get("token") ?? "";
    val.email = searchParams.get("email") ?? "";

    dispatch(
      resetPasswordConfirmThunk({
        data: val,
        navigate,
      }),
    );
  };

  useEffect(() => {
    dispatch(errorSlice.actions.resetError());
  }, []);

  return (
    <Page>
      <Title title={"Восстановление пароля"} />
      <StyledForm handleSubmit={handleSubmit(onSubmitHandler)}>
        <FormFieldInput<FormSchemaConfirmForgotPasswordPage>
          type="password"
          name="password"
          control={control}
          register={register}
          error={errors.password}
          title={"Новый пароль"}
          placeholder={"Введите пароль"}
          isRequiredField
          autoComplete="new-password"
          autoFocus
        />
        <FormFieldInput<FormSchemaConfirmForgotPasswordPage>
          type="password"
          name="password_confirmation"
          control={control}
          register={register}
          error={errors.password_confirmation}
          title={"Повторите новый пароль"}
          placeholder={"Введите пароль"}
          isRequiredField
          autoComplete={"new-password"}
        />

        <ErrorMessage />

        <Button
          className={style.submit}
          name={"Сохранить пароль"}
          disabled={
            isSubmitting ||
            !isDirty ||
            (isSubmitted && !isValid && !isDirty) ||
            auth.loading
          }
          isLoading={auth.loading}
          type={"submit"}
          onClick={() => {
            dispatch(errorSlice.actions.resetError());
          }}
        />

        <div className={style.other}>
          <span>Вспомнили пароль? </span>
          <LinkBackPage to={routes.login} />
        </div>
      </StyledForm>
    </Page>
  );
};
