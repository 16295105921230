import styles from "./Loader.module.scss";
import ContentLoader from "react-content-loader";
import cn from "classnames";

interface LoaderProps {
  className?: string;
  title?: string;
}
const Main = ({ className, title }: LoaderProps) => {
  return (
    <div className={cn(styles.loader, className)}>
      <div className={styles.wrapper}>
        <div className={styles.lds} />
        {title && <span className={styles.title}>{title}</span>}
      </div>
    </div>
  );
};

const Content = ({ className }: LoaderProps) => {
  return (
    <div className={cn(styles.contentWrapper, className)}>
      <ContentLoader
        speed={1.5}
        width={"100%"}
        backgroundColor="#f1f3f5"
        foregroundColor="#DEE4EB"
      >
        <rect x="0" y="0" rx="3" ry="3" width="100%" height="100%" />
      </ContentLoader>
    </div>
  );
};

export const Loader = {
  Content,
  Main,
};
