import { ReactNode } from "react";

import cn from "classnames";

import style from "./StyledForm.module.scss";

export interface StyledFormProps {
  children?: ReactNode | ReactNode[];
  className?: string;
  handleSubmit: (...props: any) => void;
}
export const StyledForm = ({
  className,
  handleSubmit,
  children,
}: StyledFormProps) => {
  return (
    <form className={cn(style.form, className)} onSubmit={handleSubmit}>
      {children}
    </form>
  );
};
