import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { routes } from "@/pages/routes";
import { errorSlice } from "@/store/error";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppDispatch } from "@/hooks/useAppDispatch.hook";
import { getAuthState, otpRequestThunk } from "@/store/auth";
import { Button, FormFieldCheckbox, FormFieldInput } from "@/ui";
import { ErrorMessage, Page, StyledForm, Title } from "@/components";
import { useFormErrorFromBackend } from "@/hooks/useFormErrorFromBackend";
import {
  formSchemaOtpRequestPage,
  FormSchemaOtpRequestPage,
} from "@/constants/validateSchema";

import style from "./OtpRequestPage.module.scss";
const defaultValuesForm: FormSchemaOtpRequestPage = {
  email: import.meta.env.VITE_DEV_EMAIL_USER || "",
  remember_me: false,
};

export const OtpRequestPage = () => {
  const dispatch = useAppDispatch();
  const auth = useSelector(getAuthState);
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isSubmitted, isDirty },
    setError,
  } = useForm<FormSchemaOtpRequestPage>({
    resolver: zodResolver(formSchemaOtpRequestPage),
    defaultValues: defaultValuesForm,
  });

  useFormErrorFromBackend<FormSchemaOtpRequestPage>({
    setError,
    fieldNames: ["email"],
  });

  const onSubmitHandler = (values: FormSchemaOtpRequestPage) => {
    dispatch(
      otpRequestThunk({
        data: values,
        navigate,
      }),
    );
  };

  useEffect(() => {
    dispatch(errorSlice.actions.resetError());
  }, []);

  return (
    <Page>
      <Title title={"Вход по коду"} />
      <StyledForm handleSubmit={handleSubmit(onSubmitHandler)}>
        <FormFieldInput<FormSchemaOtpRequestPage>
          type="input"
          name="email"
          isRequiredField
          title={"E-mail"}
          control={control}
          register={register}
          error={errors.email}
          placeholder={"Введите e-mail"}
          autoComplete={"email"}
          autoFocus
        />

        <div className={style.actions}>
          <FormFieldCheckbox<FormSchemaOtpRequestPage>
            titleCheckbox={"Запомнить меня"}
            name="remember_me"
            control={control}
            error={errors.remember_me}
          />
        </div>

        <ErrorMessage />

        <Button
          className={style.submit}
          name={"Отправить код"}
          type={"submit"}
          disabled={
            isSubmitting ||
            !isDirty ||
            (isSubmitted && !isValid && !isDirty) ||
            auth.loading
          }
          isLoading={auth.loading}
          onClick={() => {
            dispatch(errorSlice.actions.resetError());
          }}
        />

        <div className={style.other}>
          <Link
            to={routes.login}
            className={style.link}
            onClick={() => {
              dispatch(errorSlice.actions.resetError());
            }}
          >
            Войти по логину и паролю
          </Link>
        </div>
      </StyledForm>
    </Page>
  );
};
