import style from "./Button.module.scss";

import cn from "classnames";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 18, color: "#3d935e" }} spin />
);

type TButtonType = "button" | "submit" | "reset" | undefined;

interface ButtonProps {
  name: string;
  className?: string;
  onClick?: () => void;
  isLoading?: boolean;
  type?: TButtonType;
  disabled?: boolean;
  ghost?: boolean;
  secondary?: boolean;
}
export const Button = ({
  name,
  onClick,
  className,
  isLoading = false,
  type = "button",
  disabled = false,
  ghost = false,
  secondary = false,
  ...props
}: ButtonProps) => {
  return (
    <button
      {...props}
      onClick={onClick}
      className={cn(
        style.button,
        className,
        isLoading || disabled ? style.buttonDisabled : "",
        ghost && style.ghost,
        secondary && style.secondary,
      )}
      type={type}
      disabled={isLoading || disabled}
    >
      {name}
      {isLoading && <Spin className={style.loader} indicator={antIcon} />}
    </button>
  );
};
