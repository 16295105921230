import style from "./ResetLinkSentPage.module.scss";
import { Image } from "@/ui";
import resetImage from "@/assets/images/resetImage.png";
import { useLocation } from "react-router";
import { Page, Title } from "@/components";

export const ResetLinkSentPage = () => {
  const location = useLocation();
  const { email } = location.state;

  return (
    <Page>
      <Title title={"Уведомление о восстановлении пароля"} />
      <div className={style.wrapper}>
        <Image src={resetImage} className={style.image} />
        <p className={style.text}>
          На указанный e-mail <span className={style.email}>{email}&nbsp;</span>{" "}
          было отправлено письмо. Перейдите в почту и следуйте указанным
          инструкциям.
        </p>
      </div>
    </Page>
  );
};
