import style from "./GetRequestCodeAgain.module.scss";

import React from "react";

interface GetRequestCodeAgainProps {
  handleClickButton: React.MouseEventHandler<HTMLElement>;
  receiver: null | string;
  type: "email" | "phone";
}

const getDescription = (receiver: string | null, type: "email" | "phone") => {
  if (type === "email") {
    return receiver
      ? `PIN-код был выслан на вашу почту: ${receiver}`
      : `Письмо не было отправлено, попробуйте еще раз`;
  }

  if (type === "phone") {
    return receiver
      ? `PIN-код был выслан на ваш телефон: ${receiver}`
      : `Письмо не было отправлено, попробуйте еще раз`;
  }
};

export const GetRequestCodeAgain = ({
  handleClickButton,
  receiver,
  type,
}: GetRequestCodeAgainProps) => {
  return (
    <div className={style.container}>
      <p className={style.description}>{getDescription(receiver, type)}</p>
      <div className={style.getOtpCodeAgain}>
        <p className={style.description}>Не приходит PIN?</p>
        <button
          className={style.getOtpCodeAgainButton}
          onClick={handleClickButton}
          type="button"
        >
          Выслать PIN еще раз
        </button>
      </div>
    </div>
  );
};
