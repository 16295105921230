import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { errorSlice } from "@/store/error";
import { Button, FormFieldInput } from "@/ui";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppDispatch } from "@/hooks/useAppDispatch.hook";
import { useResendCodeTimer } from "@/pages/twoFAPage/hooks";
import { ErrorMessage, GetRequestCodeAgain } from "@/components";
import { useFormErrorFromBackend } from "@/hooks/useFormErrorFromBackend";
import {
  confirmPhoneByCodeThunk,
  getConfirmCodePhoneThunk,
  getUserState,
} from "@/store/user";
import {
  formSchemaConfirmPhoneByCodeModal,
  FormSchemaConfirmPhoneByCodeModal,
} from "@/constants/validateSchema";

import style from "./ConfirmModal.module.scss";
import { ConfirmModalProps, Container } from "./Container";
import { formatPhoneNumber } from "@/utils/formatPhoneNumber";

const defaultValuesForm: FormSchemaConfirmPhoneByCodeModal = {
  code: "",
};

export const ConfirmPhone = ({ onClose }: ConfirmModalProps) => {
  const dispatch = useAppDispatch();
  const user = useSelector(getUserState);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isSubmitted, isDirty },
    setError,
  } = useForm<FormSchemaConfirmPhoneByCodeModal>({
    resolver: zodResolver(formSchemaConfirmPhoneByCodeModal),
    defaultValues: defaultValuesForm,
  });

  useFormErrorFromBackend<FormSchemaConfirmPhoneByCodeModal>({
    setError,
    fieldNames: ["code"],
  });

  useEffect(() => {
    if (user.data.phone_verified && !user.editing) {
      onClose();
    }
  }, [user.data.phone_verified, user.editing]);

  const onSubmitHandler = async (values: FormSchemaConfirmPhoneByCodeModal) => {
    dispatch(
      confirmPhoneByCodeThunk({
        data: values,
      }),
    );
  };

  const getOtpCodeAgainCallback = () => {
    dispatch(errorSlice.actions.resetError());

    dispatch(getConfirmCodePhoneThunk());
  };

  const { handleClick: onGetOtpCodeAgain } = useResendCodeTimer(
    60000,
    getOtpCodeAgainCallback,
    [],
  );

  useEffect(() => {
    onGetOtpCodeAgain();
  }, []);

  return (
    <Container
      title={"Подтверждение телефона"}
      isLoading={user.editing}
      onClose={onClose}
      hint={`На указанный номер телефона было отправлено SMS сообщение с кодом подтверждения. Введите код в поле и подтвердите номер телефона.`}
    >
      <form onSubmit={handleSubmit(onSubmitHandler)} className={style.form}>
        <FormFieldInput<FormSchemaConfirmPhoneByCodeModal>
          type="input"
          name="code"
          isRequiredField
          title={"Код подтверждения"}
          control={control}
          register={register}
          error={errors.code}
          placeholder={"Введите код"}
          autoFocus
          maxLength={6}
        />

        <GetRequestCodeAgain
          handleClickButton={onGetOtpCodeAgain}
          receiver={formatPhoneNumber(user.otpPhone)}
          type="phone"
        />

        <ErrorMessage />

        <Button
          className={style.submit}
          name={"Подтвердить"}
          disabled={
            isSubmitting ||
            !isDirty ||
            (isSubmitted && !isValid && !isDirty) ||
            user.editing
          }
          type={"submit"}
          isLoading={user.editing}
          onClick={() => {
            dispatch(errorSlice.actions.resetError());
          }}
        />
      </form>
    </Container>
  );
};
